import styled, { createGlobalStyle } from 'styled-components';

export const PageLayout = styled.div`
  // max-width: 1440px;
  margin: 0 auto;
`;

export const MainContainer = styled.div<{ enableParallax: boolean }>`
  height: ${({ enableParallax }) => (enableParallax ? '100vh' : 'inherit')};
  transform-style: ${({ enableParallax }) => (enableParallax ? 'preserve-3d' : 'inherit')};
  perspective: ${({ enableParallax }) => (enableParallax ? '9px' : 'inherit')};
  perspective-origin: ${({ enableParallax }) => (enableParallax ? 'center' : 'inherit')};
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  overflow-y: ${({ enableParallax }) => (enableParallax ? 'scroll' : 'inherit')};
  overflow-x: ${({ enableParallax }) => (enableParallax ? 'hidden' : 'inherit')};

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const GlobalWrapper = createGlobalStyle`
  body {
    overscroll-behavior-y: none;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
`;
