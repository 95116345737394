import { Footer, Header } from '@vfit/shared/components';
import { PageLayout } from 'apps/business/styles/PageLayout.style';
import React, { useEffect } from 'react';
import { Widgets } from '@vfit/business/components';
import { dehydrate } from 'react-query';
import { API, PAGES, checkWindow } from '@vfit/shared/data-access';
import { IPageNavigation } from '@vfit/shared/models';
import { useFooter, useHeader, CMS_CONFIG, useCmsConfig } from '@vfit/business/data-access';
import prefetchAllQueries from 'libs/business/data-access/src/lib/cms/prefetchFS';
import { tracking, useTrackingProvider } from '@vfit/shared/providers';

export default function PageNotFound() {
  const pageNavigation = useCmsConfig(
    CMS_CONFIG[PAGES.PAGE_NOT_FOUND],
    API.CMS_GET_PAGE_BUSINESS_NAVIGATION
  ) as IPageNavigation;

  const { header } = useHeader(pageNavigation);
  const { footer } = useFooter(pageNavigation);

  const aemData = useCmsConfig(CMS_CONFIG[PAGES.PAGE_NOT_FOUND], API.CMS_PAGE_NOT_FOUND);
  const { tagging } = aemData;

  const { isReady: isReadyTagging } = useTrackingProvider();

  const querystringParams = checkWindow() && window.location.search;
  const urlParams = new URLSearchParams(querystringParams);
  const isApp = checkWindow() && urlParams.get('app');

  useEffect(() => {
    if (isReadyTagging) {
      tracking(
        {
          event_name: [tagging?.event],
          page_section: tagging?.pageSection,
          page_subsection: tagging?.pageSubsection,
          page_type: tagging?.pageType,
          page_error: '404_page-not-found_',
          page_error_code: '404',
          event_category: [tagging?.eventCategory],
        },
        'view'
      );
    }
  }, [isReadyTagging]);

  return (
    <div>
      {!isApp && <Header header={header} />}
      <PageLayout className="noPadding">
        <Widgets aemElements={aemData} />
      </PageLayout>
      {!isApp && <Footer footer={footer} />}
    </div>
  );
}

export async function getStaticProps() {
  const CMSData = await prefetchAllQueries(CMS_CONFIG[PAGES.PAGE_NOT_FOUND]);
  return {
    props: {
      page: {
        params: { elements: CMSData.getQueryData('getPageNotFound') },
      },
      dehydratedState: dehydrate(CMSData),
    },
  };
}

